/**
 * @file
 *
 * This file contains the hook that is used customizing the router to include the language query parameter.
 */

import { useRouter } from 'next/router';

import { useLanguage } from 'store/language';

export default function useLocalizedRouter() {
  const router = useRouter();
  const originalPush = router.push;

  router.push = (url, as, options) => {
    const language = useLanguage.get();

    const _url = url;
    const _as =
      typeof as === 'object'
        ? { ...as, query: { ...as?.query, lang: language } }
        : { pathname: as, query: { lang: language } };

    return originalPush(_url, _as, options);
  };

  return router;
}
